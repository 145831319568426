import { AuthContext } from "@/hooks/auth";
import { useAppSelector, useAppDispatch } from "@/hooks/hooks";
import { logOut, loginSuccess } from "@/redux/slices/userSlice";
import { persistor } from "@/redux/store";
import { STORAGE_KEYS, UserRole } from "@/services/constants";
// import { setCookie } from "@/services/cookie";
import LocalStorage from "@/services/storage";
import { IUser, IUserPartner } from "@/types/user";
import { ReactNode, useEffect } from "react";

const storage = new LocalStorage();

export interface AuthData {
	data: IUser & IUserPartner;
	token: string;
	message: string;
	status: boolean;
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
	// Change user selector from store due to store issues with persist
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const storedAuthToken = storage.getItem(STORAGE_KEYS.TOKEN);
		// const storedUser = JSON.parse(localStorage.getItem("MG:USER") || "{}");
		const storedUser: any = storage.getItem(STORAGE_KEYS.USER);

		if (storedAuthToken) {
			dispatch(loginSuccess(storedUser));
		}
	}, [dispatch]);

	const setAuthUser = (data: AuthData) => {
		const { data: user, token } = data;

		if (token) {
			storage.setItem(STORAGE_KEYS.TOKEN, data.token);
		}

		storage.setItem(STORAGE_KEYS.USER, user);
		storage.setItem(
			STORAGE_KEYS.ACCOUNT,
			user.role === UserRole.BUSINESS_ADMIN ? "admin" : "guest"
		);

		dispatch(loginSuccess(user));

		switch (user.role) {
			case UserRole.PARTNER_SUPER_ADMIN:
				storage.setItem(STORAGE_KEYS.COMPANY_ID, user.company_id);
				storage.setItem(STORAGE_KEYS.BUSINESS_ID, user.business.id);
				storage.setItem(STORAGE_KEYS.USER_ID, user.id);
				break;
			case UserRole.PARTNER_ADMIN:
				storage.setItem(STORAGE_KEYS.USER_ID, user.id);
				storage.setItem(STORAGE_KEYS.COMPANY_ID, user.company_id);
				break;
			default:
				storage.setItem("ID", user.business_id);
		}
		// if (data) {
		// 	const user = data.data;
		// 	if (data.token) {
		// 		setCookie("TOKEN_COOKIE", data.token, 0.41);
		// 		storage.setItem("TOKEN_COOKIE", data.token);
		// 	}
		// 	storage.setItem("MG:USER", user);
		// 	dispatch(loginSuccess(user));

		// 	//Update to PARTNER_SUPER_ADMIN & PARTNER_ADMIN & BUSINESS_ADMIN
		// 	storage.setItem(
		// 		"ACCOUNT_COOKIE",
		// 		user.role === "BUSINESS_ADMIN" ? "admin" : "guest"
		// 	);
		// 	//change to constant later
		// 	if (user.role == "PARTENER_SUPER_ADMIN") {
		// 		storage.setItem("MG:COMPANYID", user?.company_id);
		// 		storage.setItem("MG:BUSINESSID", user?.business.id);
		// 		storage.setItem("MG:USERID", user?.id);
		// 	} else if (user.role == "PARTNER_ADMIN") {
		// 		// console.log(user);
		// 		storage.setItem("MG:USERID", user?.id);
		// 		storage.setItem("MG:COMPANYID", user?.company_id);
		// 		// } else if (user.role === "BUSINESS_ADMIN") {
		// 	} else {
		// 		storage.setItem("ID", user.business_id);
		// 	}
		// } else {
		// 	console.error("Invalid data structure for setAuthUser");
		// }
	};

	const logout = () => {
		localStorage.clear();
		dispatch(logOut());
		storage.removeItem("TOKEN_COOKIE");
		storage.removeItem("ACCOUNT_COOKIE");
		storage.removeItem("ID");
		persistor.pause();
		persistor.flush().then(() => {
			return persistor.purge();
		});
		// notifyWithIcon('success', 'user logged out!');
	};

	return (
		<AuthContext.Provider value={{ user, setAuthUser, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
