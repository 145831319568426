import FormInput from "@/components/input/FormInput";
import { Button } from "@/components/ui/button";
// import { useAuth } from "@/hooks/auth";
import {
	useRegisterPartnerMutation,
	useVerifyPartnerRegistrationRequestQuery,
} from "@/redux/features/api/partnerEndpoints";
import { notifyWithIcon, useQueryParams } from "@/services/utilities";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
// import { useNavigate } from "react-router";
import BuildingsWelcomeRegister from "../BuildingsWelcomeRegister";
import useCustomToast from "@/components/CustomToast";
import { LANDING_ENVIRONMENT_LINK } from "@/services/constants";
// import { UserRole } from "@/services/constants";

const RegisterPartner = () => {
	const query = useQueryParams();
	const token = query.get("token");
	const toast = useCustomToast();
	// const navigate = useNavigate();
	// const { setAuthUser } = useAuth();
	const [showPassword, setShowPassword] = useState(false);
	// use isFetching for below when i want to add loading state
	const { data, isSuccess, isError } =
		useVerifyPartnerRegistrationRequestQuery(
			{ token: token! },
			{ skip: !token }
		);
	const [
		registerPartner,
		{
			isSuccess: isRegisterSuccess,
			isError: isRegisterError,
			isLoading,
			error: registerError,
			data: registerData,
		},
	] = useRegisterPartnerMutation();

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		if (isSuccess) {
			reset({
				fullName: data?.data?.superadmin_name || "",
				email: data?.data?.superadmin_email || "",
			});
		} else if (isError) {
			toast("invalid credentials", {
				id: "login_message",
				type: "error",
			});
		}
	}, [isSuccess, isError, data]);

	// Use to handle submission of registration data
	useEffect(() => {
		if (isRegisterSuccess) {
			const message = registerData.message;
			toast(message || "registration successful", {
				id: "login_message",
				type: "error",
			});
			window.open(LANDING_ENVIRONMENT_LINK, "self")
			// console.log(registerData.data);
			// const {data, token }= registerData.data.data
			// setTimeout(() => {
			// 	// setAuthUser(...data, token: token);

			// 	if (
			// 		registerData?.data?.user?.role === UserRole.PARTNER_SUPER_ADMIN ||
			// 		registerData?.data?.user?.role === UserRole.PARTNER_ADMIN
			// 	) {
			// 		navigate("/partner/rooms");
			// 	} else if (registerData?.user.role === "BUSINESS_ADMIN") {
			// 		navigate("/admin/location-management");
			// 	}
			// }, 2500);
		} else if (isRegisterError && registerError) {
			if (registerError) {
				const errorMessage =
					(
						registerError as {
							data?: { message?: string };
						}
					)?.data?.message || "An error occurred";
				return toast(errorMessage, {
					id: "register_message",
					type: "error",
				});
			}
		}
	}, [isRegisterError, isRegisterSuccess]);

	const onSubmit = (data: any) => {
		if (!token) {
			return notifyWithIcon("", "Error with token");
		}
		const submittedData = {
			name: data.fullName,
			email: data.email,
			password: data.password,
			password_confirmation: data.confirmPassword,
		};
		registerPartner({ data: submittedData, token: token });
	};

	return (
		// Add loading state also
		<div className="mx-auto mt-auto flex h-full w-full flex-col items-center justify-center gap-x-8 px-8 lg:flex-row xl:px-24">
			<BuildingsWelcomeRegister />
			{/* Form  */}
			<div className="flex h-fit w-full max-w-[488px] flex-col border border-[#E5E5E7] shadow-lg">
				<div className="flex flex-col gap-y-8 px-8 py-6 text-left">
					<div>
						<h1 className="text-[22px] font-semibold text-basecolor">
							Create an account
						</h1>
						<p className="font-normal text-[#858C95]">
							Already have an account ?{" "}
							<a className="text-primary" href="/login">
								Login
							</a>
						</p>
					</div>
					<form
						id="register-form"
						className="flex flex-col gap-y-4 "
						onSubmit={handleSubmit(onSubmit)}
					>
						<FormInput
							label="Full Name *"
							inputType="text"
							{...register("fullName", {
								required: "Full Name is required",
							})}
							error={errors.fullName}
						/>
						<FormInput
							label="Email Address *"
							disabled={true}
							inputType="text"
							{...register("email", {
								required: "Email is required",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Invalid email address",
								},
							})}
							error={errors.email}
						/>
						<div className="relative flex">
							<FormInput
								label="Password *"
								inputType={showPassword ? "text" : "password"}
								{...register("password", {
									required: "Password is required",
									minLength: {
										value: 8,
										message:
											"Password must be at least 8 characters long",
									},
								})}
								error={errors.password}
							/>
							<button
								type="button"
								className="absolute right-4 top-[60%] flex items-center gap-2 text-[12px] font-semibold text-primary "
								onClick={() => setShowPassword(!showPassword)}
							>
								{" "}
								{showPassword && <FaEye />}
								{!showPassword && <FaEyeSlash />}{" "}
							</button>
						</div>

						<FormInput
							label="Confirm Password*"
							inputType="password"
							{...register("confirmPassword", {
								required: "Confirm Password is required",
								validate: (value) =>
									value === getValues("password") ||
									"Passwords do not match",
							})}
							error={errors.confirmPassword}
						/>
					</form>
				</div>

				<div className="flex  flex-col items-center justify-between gap-y-4 bg-[#FAFBFC] px-8 py-4 lg:flex-row">
					<Button
						form="register-form"
						type="submit"
						className="w-full lg:w-[114px]"
					>
						{isLoading ? (
							<LoadingOutlined style={{ fontSize: 24 }} spin />
						) : (
							" Sign Up"
						)}
					</Button>
					{/* <div className="flex w-full flex-col items-center gap-2 lg:w-fit lg:flex-row">
						<p className="whitespace-nowrap text-sm font-light text-[#858C95]">
							Or <span className="text-">,</span> Sign in with
						</p>
						<Button className="flex w-full gap-2 border-[#E5E5E7] bg-white text-basecolor shadow-sm ">
							<img src={GoogleLogo} alt="Google_button" />
							Google
						</Button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default RegisterPartner;
