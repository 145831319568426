import Intercom, { update } from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { useAppSelector } from "./hooks";
import CryptoJS from "crypto-js";

const useHelpIntercomIdentify: () => void = () => {
	const user = useAppSelector((state) => state.user.user);

	const handleIntercomIdentify = () => {
		if (user) {
			const user_hash = CryptoJS.HmacSHA256(
				user.email,
				import.meta.env.VITE_INTERCOM_APP_USER_HASH
			).toString();

			Intercom({
				app_id: import.meta.env.VITE_INTERCOM_APP_ID,
				user_id: user.id.toString(),
				name: user.name ?? "",
				email: user.email ?? "",
				company: user?.company?.name ?? "",
				user_hash,
			});
			update({
				user_id: user.id.toString(),
				name: user.name ?? "",
				email: user.email ?? "",
				company: user?.company?.name ?? "",
				user_hash,
			});
		}
	};

	useEffect(() => {
		handleIntercomIdentify();
	}, [user]);
};

export default useHelpIntercomIdentify;
