import UploadImage from "@/components/common/UploadImage";
import useCustomToast from "@/components/CustomToast";
import FormInput from "@/components/input/FormInput";
import Loader from "@/components/Loader/Loader";
import { Button } from "@/components/ui/button";
import { useUpdateBusinessMutation } from "@/redux/features/api/partnerEndpoints";
import { useState } from "react";

const AddBusiness = ({ onClose }) => {
	const [updateBusiness, { isLoading }] = useUpdateBusinessMutation();
	const toast = useCustomToast();
	// const { isLoading } = useUpdateBusinessMutation();

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone_number: "",
		address: "",
		city: "",
		country: "",
		zipCode: "",
	});
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const handleSubmit = async () => {
		const data = formData;
		try {
			const response = await updateBusiness({ data }).unwrap();
			toast(response.message, {
				id: "business-success",
				type: "success",
			});
			onClose();
		} catch (error) {
			console.error("Error updating business:", error);
		}
	};

	return (
		<div className="flex w-full flex-col gap-y-3 rounded-[30px] bg-[#F5F5F5] ">
			<div className="flex w-full cursor-pointer justify-center rounded-tl-[30px] rounded-tr-[30px]  border-b border-b-primary bg-white pb-2 pt-4 text-center text-[15px] font-semibold text-primary ">
				<h1 className="">Add Your Business</h1>
			</div>
			<div className="flex flex-col gap-4 px-4">
				<div className="flex items-center gap-4">
					<div className="aspect-square w-[108px] rounded-[20px] border-[5px] border-white shadow-[0px_0px_5px_rgba(0,0,0,0.25)]"></div>
					<div>
						<UploadImage />
						<p className="pt-2 text-[12px] text-[#6B7280]">
							File must be a JPG or PNG
						</p>
						<p className="text-[12px] text-[#6B7280]">
							Max Size of 5MB
						</p>
					</div>
				</div>
				<div className="flex flex-col gap-y-3">
					<FormInput
						inputType="text"
						placeholder="Business Name"
						label="Business Name"
						name="name"
						value={formData.name}
						onChange={handleChange}
					/>
					<div className="flex gap-4">
						<FormInput
							inputType="text"
							placeholder="info@businessemail.com"
							label="Business Email Address"
							name="email"
							value={formData.email}
							onChange={handleChange}
						/>
						<FormInput
							inputType="text"
							placeholder="+1 (000)000 - 000"
							label="Business Phone Number"
							name="phone_number"
							value={formData.phone_number}
							onChange={handleChange}
						/>
					</div>

					<FormInput
						inputType="text"
						placeholder="Address"
						label="Business Address"
						name="address"
						value={formData.address}
						onChange={handleChange}
					/>
					<div className="flex gap-2">
						<FormInput
							inputType="text"
							placeholder="City"
							name="city"
							value={formData.city}
							onChange={handleChange}
						/>
						<FormInput
							inputType="text"
							placeholder="Country"
							name="country"
							value={formData.country}
							onChange={handleChange}
						/>
						<FormInput
							inputType="text"
							placeholder="Zip Code"
							name="zipCode"
							value={formData.zipCode}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>

			<div className=" flex w-full gap-2 px-4 py-6">
				<Button
					onClick={onClose}
					className="w-full"
					variant="outlinecustom"
				>
					Cancel
				</Button>
				<Button
					className="w-full"
					variant="default"
					onClick={handleSubmit}
					disabled={isLoading}
				>
					{isLoading ? <Loader size={12} /> : "Save"}
				</Button>
			</div>
		</div>
	);
};

export default AddBusiness;
