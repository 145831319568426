import Loader from "@/components/Loader/Loader";
import Modal from "@/components/modals/Modal";
import AddBusiness from "@/components/modals/partner/AddBusiness";
import AddMember from "@/components/modals/partner/AddMember";
import CompanyInfoOverview from "@/components/partner/members/CompanyInfoOverview";
import EmptyMemberList from "@/components/partner/members/EmptyMemberList";
import MemberList from "@/components/partner/members/MemberList";
import DefaultTopbar from "@/components/topbar/DefaultTopbar";
import { DialogHeader, DialogTitle } from "@/components/ui/dialog";
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
} from "@/components/ui/drawer";
import { useAppSelector } from "@/hooks/hooks";
import useScreenDimension from "@/hooks/useScreenDimension";
import { cn } from "@/lib/utils";
import {
	useAddNewMemberMutation,
	useGetAllPartnerTeamMembersQuery,
	useGetPartnerAccountDetailsQuery,
} from "@/redux/features/api/partnerEndpoints";
import LocalStorage from "@/services/storage";
import { TeamMemberRoles } from "@/types/team-member";
import { useState } from "react";

const Members: React.FC = () => {
	const storage = new LocalStorage();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const partner_id = storage.getItem("MG:USERID") as number;
	const [activeTab, setActiveTab] = useState<"admin" | "guests">("admin");
	const [showAddMember, setShowAddMember] = useState(false);
	const [showAddPartner, setShowAddPartner] = useState(false);
	const addNewMemberMutation = useAddNewMemberMutation();

	const { width } = useScreenDimension();
	const isMobile = width < 428;

	const { data: partnerData, isFetching: isPartnerAccountDetailLoading } =
		useGetPartnerAccountDetailsQuery({
			id: partner_id,
		});

	const { data, isFetching } = useGetAllPartnerTeamMembersQuery();
	const isEmptyMembers = data?.data?.admin.length === 0;

	const isBusinessSetupDone = partnerData?.data?.is_setup_done;
	
	return (
		<div className="flex h-full flex-1 flex-col overflow-hidden p-4 md:p-8">
			<DefaultTopbar title="All Reservations" />
			<div className="flex h-full flex-1 flex-col overflow-hidden md:gap-y-4">
				<CompanyInfoOverview
					partnerData={partnerData}
					isFetching={isPartnerAccountDetailLoading}
				/>
				<div className="mb-4 mt-2 flex w-full border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95]">
					<div
						onClick={() => setActiveTab("admin")}
						className={cn(
							"flex w-full cursor-pointer justify-center py-2",
							{
								"border-b-2 border-b-primary text-primary":
									activeTab === "admin",
							}
						)}
					>
						<p>Admin</p>
					</div>
					<div
						onClick={() => setActiveTab("guests")}
						className={cn(
							"flex w-full cursor-pointer  justify-center py-2",
							{
								"border-b-2 border-b-primary text-primary":
									activeTab === "guests",
							}
						)}
					>
						<p>Guests</p>
					</div>
				</div>
				{isFetching ? (
					<div className="mb-4 flex-1">
						<Loader size={24} />
					</div>
				) : (
					<>
						{isEmptyMembers && !isBusinessSetupDone ? (
							<div className="flex-1">
								<EmptyMemberList
									setShowAddPartner={setShowAddPartner}
								/>
							</div>
						) : (
							<>
								{activeTab === "admin" && (
									<MemberList
										list={data?.data?.admin}
										setShowAddMember={() =>
											setShowAddMember(true)
										}
										memberType="admin"
									/>
								)}
								{activeTab === "guests" && (
									<MemberList
										list={data?.data?.guest}
										setShowAddMember={() =>
											setShowAddMember(true)
										}
										memberType="guest"
									/>
								)}
							</>
						)}
					</>
				)}
			</div>

			{/* Tab content  */}
			{userRole !== TeamMemberRoles.PartnerAdmin && (
				<Modal
					dialogClassName="max-w-xl p-0 w-full"
					open={showAddPartner}
					setOpen={setShowAddPartner}
				>
					<AddBusiness onClose={() => setShowAddPartner(false)} />
				</Modal>
			)}
			{/* <Modal
				dialogClassName="!rounded-[30px] bg-[#F5F5F5] !min-w-[600px] pt-12"
				open={!isMobile && showAddGuest}
				setOpen={setShowAddGuest}
			>
				<>
					<DialogHeader className="absolute left-0 top-0 mx-auto flex w-full rounded-tl-[30px] rounded-tr-[30px] border-b border-b-primary bg-white py-4 text-center">
						<DialogTitle className="text-center text-primary ">
							Add Guest
						</DialogTitle>
					</DialogHeader>
					<AddGuest handleClose={() => setShowAddGuest(false)} />
				</>
			</Modal> */}
			{userRole !== TeamMemberRoles.PartnerAdmin && (
				<Modal
					dialogClassName="!rounded-[30px] bg-[#F5F5F5] pt-12 msm:max-w-[95%]"
					open={!isMobile && showAddMember}
					// setOpen={(val) => setShowAddMember(val)}
					setOpen={(value) => {
						if (!addNewMemberMutation[1].isLoading)
							return setShowAddMember(value);
					}}
				>
					<>
						<DialogHeader className="absolute left-0 top-0 mx-auto flex w-full rounded-tl-[30px] rounded-tr-[30px] border-b border-b-primary bg-white py-4 text-center">
							<DialogTitle className="text-center text-primary ">
								Add Member
							</DialogTitle>
						</DialogHeader>
						<AddMember
							handleClose={() => setShowAddMember(false)}
							addNewMember={addNewMemberMutation}
						/>
					</>
				</Modal>
			)}

			{/* <Drawer
				open={isMobile && showAddGuest}
				onOpenChange={setShowAddGuest}
			>
				<DrawerContent className=" mb-4 bg-[#F9F9F9] px-4">
					<DrawerHeader>
						<DrawerTitle>Add Guest (Send invite)</DrawerTitle>
					</DrawerHeader>
					<AddGuest handleClose={() => setShowAddGuest(false)} />
				</DrawerContent>
			</Drawer> */}
			<Drawer
				open={isMobile && showAddMember}
				onOpenChange={(value) => {
					if (!addNewMemberMutation[1].isLoading)
						setShowAddMember(value);
				}}
				dismissible={!addNewMemberMutation[1].isLoading}
			>
				<DrawerContent className=" mb-4 bg-[#F9F9F9] px-4">
					<DrawerHeader>
						<DrawerTitle>Add Member (Send invite)</DrawerTitle>
					</DrawerHeader>
					<AddMember
						handleClose={() => setShowAddMember(false)}
						addNewMember={addNewMemberMutation}
					/>
				</DrawerContent>
			</Drawer>
			{/* <Drawer
				open={isMobile && showAddAdmin}
				onOpenChange={setShowAddAdmin}
			>
				<DrawerContent className=" mb-4 bg-[#F9F9F9] px-4">
					<DrawerHeader>
						<DrawerTitle>Add Admin (Send invite)</DrawerTitle>
					</DrawerHeader>
					<AddAdmin handleClose={() => setShowAddAdmin(false)} />
				</DrawerContent>
			</Drawer> */}
		</div>
	);
};

export default Members;
