import { useCallback, useEffect } from "react";
import { useAuth } from "../hooks/auth";
import { IUser } from "../types/user";
import { toTitleCase } from "@/services/utilities";

const useHeapAnalyticsIdentify: () => void = () => {
	const heap = (window as any).heap;
	const { user } = useAuth();

	const handleHeapAnalyticsIdentify = useCallback(() => {
		if (user && heap) {
			if (heap.load) heap.load(import.meta.env.VITE_HEAP_ID);
			heap.identify(user?.email);
			heap.addUserProperties({
				name: toTitleCase(user?.name),
				email: user?.email,
				phone_number:
					(user as IUser)?.phone_number ??
					user?.business?.phone_number,
			});
		}
	}, [user]);

	useEffect(() => {
		handleHeapAnalyticsIdentify();
	}, [handleHeapAnalyticsIdentify]);
};

export default useHeapAnalyticsIdentify;
