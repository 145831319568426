import CustomSelect from "@/components/common/CustomSelect";
import Search from "@/components/input/Search";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useEffect, useState } from "react";
// import { BsFiletypeCsv } from "react-icons/bs";
import AddAmenities from "@/components/modals/admin/locations/AddAmenities";
import Modal from "@/components/modals/Modal";
import {
	useDeleteAmenityMutation,
	useEditAmenityMutation,
} from "@/redux/features/apiSlice";
import { notifyWithIcon } from "@/services/utilities";
import { message } from "antd";
import AmenitiesListCard, { Amenity } from "./AmenitiesListCard";
import { useAppSelector } from "@/hooks/hooks";
import { TeamMemberRoles } from "@/types/team-member";
import useCustomToast from "@/components/CustomToast";

const AmenitiesList = ({ amenities }: { amenities: any }) => {
	const toast = useCustomToast();
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const id = location.pathname.split("/").pop();
	const [editAmenity, { isLoading, isSuccess, isError }] =
		useEditAmenityMutation();
	const [deleteAmenity] = useDeleteAmenityMutation();
	const [showAddAmenities, setShowAddAmenities] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState("");
	const [selectedItems, setSelectedItems] = useState<Amenity[]>([]);
	const [, setSelectAll] = useState<boolean>(false);
	const filteredItems = amenities.data
		.filter((item: Amenity) =>
			item.name.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.sort((a: Amenity, b: Amenity) => {
			if (sortBy === "name") {
				return a.name.localeCompare(b.name);
			}
			return 0; // No sorting by default
		});

	const handleDeleteAmenity = async (amenity_id: number) => {
		toast("Deleting amenity", {
			id: "amenity-deletion",
			type: "loading",
		});
		const result = await deleteAmenity({
			id: id!,
			amenity_id: amenity_id.toString(),
		});
		if (result.data.message == "Amenity Deleted Successfully") {
			toast(result?.data?.message, {
				id: "amenity-deletion",
				type: "success",
			});
		} else {
			toast("Error deleting amenity", {
				id: "amenity-deletion",
				type: "error",
			});
		}
	};

	const toggleSelectItem = (item: Amenity) => {
		if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.id !== item.id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		setSelectAll(false);
	};

	const handleEditAmenity = (amenity_id, data) => {
		if (data && amenity_id) {
			return editAmenity({
				id: id!,
				amenity_id: amenity_id,
				data: data,
			}).unwrap();
		}
		message.error("Error updating amenity");
	};

	useEffect(() => {
		if (isSuccess) {
			notifyWithIcon("success", "Amenity updated successfully!");
		} else if (isError) {
			notifyWithIcon("error", "Error updating amenity!");
		}
	}, [isSuccess, isError]);

	return (
		<div className="flex w-full scroll-m-0 flex-col overflow-y-auto">
			<div className="flex h-9 w-full gap-2">
				<Search
					placeholder="Search"
					className="h-8.5 flex-[0.6]"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<CustomSelect
					placeholder="Sort by"
					className="w-full flex-[0.4]"
					value={sortBy}
					options={[
						{ value: "", label: "None" },
						{ value: "name", label: "Name" },
					]}
					onChange={(selectedOption: any) =>
						setSortBy(selectedOption.value as string)
					}
				/>

				{userRole !== TeamMemberRoles.TeamMember &&
					userRole !== TeamMemberRoles.PartnerManager && (
						<Button
							variant="default"
							className=" h-full px-10"
							onClick={() => setShowAddAmenities(true)}
						>
							Add
						</Button>
					)}
			</div>

			<div className="flex flex-col gap-y-4 py-2">
				{amenities?.data.length > 0 && (
					<ScrollArea className="h-[50vh]">
						<div className="flex flex-col gap-2 px-2 py-2">
							{filteredItems.map((item, index) => (
								<AmenitiesListCard
									amenity={item}
									key={index}
									onSelect={() => toggleSelectItem(item)}
									checked={selectedItems.some(
										(selectedItem) =>
											selectedItem.id === item.id
									)}
									handleEditSave={handleEditAmenity}
									handleDeleteAmenity={handleDeleteAmenity}
									isLoading={isLoading}
								/>
							))}
						</div>
					</ScrollArea>
				)}
				{amenities?.data.length == 0 && (
					<p className="w-full py-24 text-center text-sm">
						No Amenities Available
					</p>
				)}
			</div>

			<Modal
				dialogClassName="!rounded-md max-w-sm p-4 bg-white w-full"
				open={showAddAmenities}
				setOpen={setShowAddAmenities}
			>
				<AddAmenities setShow={setShowAddAmenities} />
			</Modal>
		</div>
	);
};

export default AmenitiesList;
