import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { API_URI, STORAGE_KEYS } from "./constants";
import LocalStorage from "./storage";

// const TOKEN_COOKIE_NAME = "TOKEN_COOKIE";
// const TOKEN_EXPIRY_DAYS = 0.41;
const storage = new LocalStorage();
// const isTokenExpired = (token: string) => {
// 	try {
// 		const { exp } = jwtDecode<JWTPayload>(token);
// 		return exp * 1000 < Date.now();
// 	} catch (e) {
// 		return true;
// 	}
// };

// Promise to prevent multiple simultaneous refresh requests
let refreshTokenPromise: Promise<void> | null = null;

const refreshToken = async (): Promise<void> => {
	const accessToken = storage.getItem(STORAGE_KEYS.TOKEN) || "";

	// Do not run function if access token is expired
	if (!accessToken) return;
	if (!refreshTokenPromise) {
		refreshTokenPromise = (async () => {
			try {
				const { data: response, status } = await axios.get(
					`${API_URI}/refresh`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				);

				if (status === 200 && response) {
					localStorage.setItem(STORAGE_KEYS.TOKEN, response.token);
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					console.error("Error refreshing token:", error.response);
				}
				if ((error as AxiosError)?.response?.status === 401) {
					window.location.href = "/login";
				}
			} finally {
				refreshTokenPromise = null;
			}
		})();
	}

	await refreshTokenPromise;
};

const axiosInstance = axios.create({
	baseURL: `${API_URI}`,
	headers: {
		"Content-Type": "application/json",
	},
});

axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		// this covers what were done in the redux slices
		const token = storage.getItem(STORAGE_KEYS.TOKEN);
		if (
			config.url !== `${API_URI}/login` &&
			!config.headers.Authorization
		) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response.data;
	},
	async (error) => {
		const originalRequest = error.config;
		console.log(error.response?.status);
		if (
			error.response?.status == 401 &&
			error.response?.data?.message == "Unauthenticated." &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;
			await refreshToken();

			const newAccessToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
			if (newAccessToken) {
				originalRequest.headers["Authorization"] =
					`Bearer ${newAccessToken}`;
			}

			return axiosInstance(originalRequest);
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
