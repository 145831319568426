import { Button } from "@/components/ui/button";

const EmptyMemberList = ({ setShowAddPartner }) => {
	return (
		<div className="flex h-full w-full items-center justify-center rounded-lg border border-[#BFBFBF]">
			<div className="flex max-w-sm flex-col items-center  gap-y-2 text-center">
				<h1 className="text-2xl font-semibold text-basecolor ">
					Set up your business!{" "}
				</h1>
				<p className="text-lg font-normal text-basecolor">
					Set up your business and team members to start reserve
					spaces
				</p>
				<Button
					variant="outlinecustom"
					className="w-fit border-basecolor px-12 py-2 text-xs text-basecolor"
					onClick={() => setShowAddPartner(true)}
				>
					Start Set Up
				</Button>
			</div>
		</div>
	);
};

export default EmptyMemberList;
