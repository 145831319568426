import CustomShadSelect from "@/components/common/CustomShadSelect";
import CapacitySelector from "@/components/input/CapacitySelector";
import Search from "@/components/input/Search";
import AmenityFilter from "@/components/partner/room/AmenityFilter";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/datepicker";
import { Drawer, DrawerClose, DrawerContent } from "@/components/ui/drawer";
import { Switch } from "@/components/ui/switch";
import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import useScreenDimension from "@/hooks/useScreenDimension";
import { useGetFilterDataQuery } from "@/redux/features/api/partnerEndpoints";
import { updateBooking } from "@/redux/slices/bookingSlice";
import {
	clearAmenitiesFilter,
	setAllDayFilter,
	setDateFilter,
	setDurationFilter,
	setEndTimeFilter,
	setNameFilter,
	setStartTimeFilter,
} from "@/redux/slices/filterSlice";
import { generateTimeOptions } from "@/services/utilities";
import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { FaRegClock } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useDebouncedCallback } from "use-debounce";
import {
	RepeatTimeOptions,
	currentDay,
	durationOptions,
	// today,
} from "../../../services/constants";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useGetLocationQuery } from "@/redux/features/api/locationEndpoints";
interface RoomFilterProps {
	openFilter: boolean;
	setOpenFilter: (open: boolean) => void;
}
const RoomFilter: React.FC<RoomFilterProps> = ({
	openFilter,
	setOpenFilter,
}) => {
	const { width } = useScreenDimension();
	const isMobile = width < 800;
	const user = useAppSelector((state) => state.user.user);
	const location_id = useAppSelector((state) => state.filters.location_id);
	const firstLocationId = user?.business?.locations[0]?.id;

	const { date, start_time, end_time, duration, isAllDay } = useAppSelector(
		(state) => state.filters
	);

	const { data: locationData } = useGetLocationQuery({
		user_id: user?.business_id ?? 0,
		location_id: isNaN(parseInt(location_id as string))
			? firstLocationId
			: parseInt(location_id as string),
	});

	const generateWorkingHours = useCallback(() => {
		const working_hours = locationData?.data?.working_hours;

		if (working_hours && Array.isArray(working_hours)) {
			const timeSlots = working_hours?.find(
				(work_hour) => work_hour.day_of_week == currentDay.toLowerCase()
			)?.time_slots[0];

			const startTime = timeSlots?.start_time?.slice(0, -3);
			const endTime = timeSlots?.end_time?.slice(0, -3);

			if (!startTime || !endTime) return undefined;

			const selectedDate = DateTime.fromISO(
				date || DateTime.now().toISODate()
			);
			const today = DateTime.now();

			if (selectedDate.hasSame(today, "day")) {
				const currentTime = today.toFormat("HH:mm");
				const parsedEndTime = DateTime.fromFormat(endTime, "HH:mm");
				const parsedCurrentTime = DateTime.fromFormat(
					currentTime,
					"HH:mm"
				);

				return {
					startTime:
						parsedCurrentTime < parsedEndTime
							? startTime
							: currentTime,
					endTime,
				};
			} else {
				return {
					startTime,
					endTime,
				};
			}
		}
	}, [locationData?.data]);

	const { data, isLoading } = useGetFilterDataQuery({
		isAdmin: !user?.company,
		business_or_company_id: user?.company_id ?? user?.business_id,
	});

	const dispatch = useAppDispatch();
	const handleDateChange = (date: Date) => {
		const luxonDate = DateTime.fromJSDate(date).toISODate();
		dispatch(setDateFilter(luxonDate!));
		dispatch(updateBooking({ date: luxonDate! }));
	};
	const handleAllDayChange = () => {
		dispatch(setAllDayFilter(!isAllDay));
	};

	const debouncedSetNameFilter = useDebouncedCallback((value) => {
		dispatch(setNameFilter(value));
	}, 500);

	if (isMobile) {
		return (
			<Drawer open={openFilter} onOpenChange={setOpenFilter}>
				<DrawerContent>
					<div className="mx-auto flex h-[90svh] w-full flex-col px-6">
						<div className="flex flex-col gap-y-3">
							<div className="flex items-center justify-between">
								<h1 className="px-3 py-2 text-2xl font-semibold text-basecolor">
									Filter your search
								</h1>
								<DrawerClose asChild={false}>
									<IoClose
										className="text-3xl text-[#858C95]"
										width={24}
									/>
								</DrawerClose>
							</div>

							<ScrollArea className="no-scrollbar flex h-[70svh] w-full flex-col gap-y-4 overflow-y-scroll px-2 text-left">
								<div className="flex flex-col space-y-4">
									<Search
										onChange={(e) => {
											debouncedSetNameFilter(
												e.target.value
											);
										}}
										placeholder="Search by name"
									/>

									<div className="w-full space-y-1">
										<p className="text-sm">Date</p>
										<DatePicker
											className="h-[40px] w-full"
											title=""
											date={
												date
													? DateTime?.fromISO(
															date
														).toJSDate()
													: undefined
											}
											setDate={handleDateChange}
										/>
									</div>
									{/* Duration  */}
									{/* <div className="w-full space-y-1">
									<p className="text-sm">Duration</p>
									<div className="flex w-full items-center justify-between gap-x-4">
										<DurationSelector />
										<div className="flex w-full gap-2">
											<Switch
												id="all-day"
												className="scale-[0.82] data-[state=unchecked]:bg-[#E5E5E7] "
											/>
											All day
										</div>
									</div>
								</div> */}
									<div className="flex flex-col gap-y-2">
										<p className="text-sm">Duration</p>
										<div className="flex items-center space-x-2.5">
											<CustomShadSelect
												className="w-full text-[14px]"
												placeholder={
													<div className="flex items-center gap-3">
														<FaRegClock /> Duration
													</div>
												}
												options={durationOptions.map(
													(item) => ({
														label: item.label,
														value: item.value.toString(),
													})
												)}
												value={duration?.toString()}
												onChange={(newValue: any) => {
													dispatch(
														updateBooking({
															duration:
																newValue.value,
														})
													);
													dispatch(
														setDurationFilter(
															newValue.value
														)
													);
												}}
											/>
											<div className="flex min-w-[114px] gap-2">
												<Switch
													checked={isAllDay}
													onChange={
														handleAllDayChange
													}
													id="all-day"
													className="scale-[0.82] data-[state=unchecked]:bg-[#E5E5E7] "
												/>
												All day
											</div>
										</div>
									</div>
									<div className="w-full space-y-1">
										<p className="text-sm">Frequency</p>
										<CustomShadSelect
											placeholder="Does not repeat"
											className="min-h-[30px] w-full text-[14px]"
											options={RepeatTimeOptions}
											onChange={() => {
												//
											}}
										/>
									</div>
									<div className="w-full space-y-1">
										<p className="text-sm">Capacity</p>
										<CapacitySelector />
									</div>

									<div className="w-full space-y-1">
										<p className="text-sm">From</p>
										<CustomShadSelect
											className="text-[14px]"
											placeholder={
												<div className="flex items-center gap-3 text-[14px]">
													<FaRegClock /> 12:00{" "}
												</div>
											}
											value={start_time}
											options={generateTimeOptions(
												generateWorkingHours()
													?.startTime,
												generateWorkingHours()?.endTime
											)}
											onChange={(newValue: any) => {
												dispatch(
													setStartTimeFilter(newValue)
												);
											}}
										/>
									</div>
									<div className=" space-y-1 ">
										<p className="text-sm">To</p>
										<CustomShadSelect
											className="text-[14px]"
											placeholder={
												<div className="flex items-center gap-3 text-[14px] ">
													<FaRegClock />
													<p className="text-[14px]">
														Time
													</p>
												</div>
											}
											value={end_time}
											options={generateTimeOptions(
												end_time ?? "00:00"
											)}
											onChange={(newValue: any) => {
												dispatch(
													setEndTimeFilter(newValue)
												);
											}}
										/>
									</div>

									<div className="w-full pt-6">
										{isLoading && (
											<p className=" animate-pulse">
												Loading...
											</p>
										)}
										{data && (
											<>
												<div className="flex items-center justify-between pb-2">
													<h1 className="text-sm">
														Select Amenities
													</h1>
													<button
														className="text-[10px] text-basecolor"
														onClick={() =>
															dispatch(
																clearAmenitiesFilter()
															)
														}
													>
														Clear All
													</button>
												</div>
												<AmenityFilter
													amenities={
														data.data?.locations.find(
															(location) =>
																location.id ===
																1
														)?.amenities ?? []
													}
												/>
											</>
										)}
									</div>
								</div>
							</ScrollArea>
						</div>
						<div className="mt-auto w-full py-4">
							<Button className="w-full text-[14px]">
								Search
							</Button>
						</div>
					</div>
				</DrawerContent>
			</Drawer>
		);
	}
	return (
		<div className="h-screen  min-w-[279px] max-w-[279px] bg-[#F9F9F9] shadow-[0px_20px_25px_-6px_rgba(16,24,40,0.1)]  ">
			<div className="flex h-full flex-col gap-y-3 px-4">
				<h1 className="px-2 pt-9 text-lg font-semibold text-[#323539]">
					Filter your search
				</h1>
				<div className="no-scrollbar flex h-full w-full flex-col gap-y-2 overflow-y-auto px-2 text-left">
					<div className="pt-1">
						<Search
							onChange={(e) => {
								dispatch(setNameFilter(e.target.value));
							}}
							placeholder="Search by name"
						/>
					</div>

					<div className="w-full space-y-1">
						<p className="text-sm">Date</p>
						<DatePicker
							className="h-[40px] w-full"
							title=""
							date={
								date
									? DateTime?.fromISO(date).toJSDate()
									: undefined
							}
							setDate={handleDateChange}
						/>
					</div>

					<div className="w-full space-y-1">
						<p className="text-sm">From</p>
						<CustomShadSelect
							className="text-[14px]"
							placeholder={
								<div className="flex items-center gap-3 text-[14px] ">
									<FaRegClock />
									<p className="text-[14px]">Time</p>
								</div>
							}
							value={start_time}
							options={generateTimeOptions(
								generateWorkingHours()?.startTime,
								generateWorkingHours()?.endTime
							)}
							onChange={(newValue: any) => {
								dispatch(setStartTimeFilter(newValue));
							}}
						/>
					</div>
					<div className=" space-y-1 ">
						<p className="text-sm">To</p>
						<CustomShadSelect
							className="text-[14px]"
							placeholder={
								<div className="flex items-center gap-3 text-[14px] ">
									<FaRegClock />
									<p className="text-[14px]">Time</p>
								</div>
							}
							value={end_time}
							options={generateTimeOptions(
								start_time ?? generateWorkingHours()?.startTime,
								generateWorkingHours()?.endTime
							)}
							onChange={(newValue: any) => {
								dispatch(setEndTimeFilter(newValue));
							}}
						/>
					</div>
					{/* Duration  */}
					<div className="flex flex-row gap-y-2 lg:flex-col">
						<div className="w-full space-y-1">
							<p className="text-sm">Duration</p>
						</div>
						<CustomShadSelect
							className="w-full  text-[14px]"
							placeholder={
								<div className="flex items-center gap-3">
									<FaRegClock /> Duration
								</div>
							}
							options={durationOptions.map((item) => ({
								label: item.label,
								value: item.value.toString(),
							}))}
							value={duration?.toString()}
							onChange={(newValue: any) => {
								dispatch(
									updateBooking({
										duration: newValue.value,
									})
								);
								dispatch(setDurationFilter(newValue.value));
							}}
						/>
						<div className="flex gap-2">
							<Switch
								checked={isAllDay}
								onChange={handleAllDayChange}
								id="all-day"
								className="scale-[0.82] data-[state=unchecked]:bg-[#E5E5E7] "
							/>
							All day
						</div>
					</div>
					<CustomShadSelect
						placeholder="Does not repeat"
						className="min-h-[30px] w-full text-[14px]"
						options={RepeatTimeOptions}
						onChange={() => {
							//
						}}
					/>
					{/* <div className="flex flex-row gap-y-2 lg:flex-col">
						<div className="w-full space-y-1">
							<p className="text-sm">Duration</p>
						</div>
						<div className="flex gap-2">
							<Switch
								onChange={() => dispatch(setDurationFilter(1))}
								id="all-day"
								className="scale-[0.82] data-[state=unchecked]:bg-[#E5E5E7] "
							/>
							All day
						</div>
					</div> */}

					<div className="w-full space-y-1">
						<p className="text-sm">Capacity</p>
						<CapacitySelector />
					</div>

					<div className="w-full pt-6">
						{isLoading && (
							<p className=" animate-pulse">Loading...</p>
						)}
						{data && (
							<>
								<div className="flex items-center justify-between pb-2">
									<h1 className="text-sm">
										Select Amenities
									</h1>
									<button
										className="text-[10px] text-basecolor"
										onClick={() =>
											dispatch(clearAmenitiesFilter())
										}
									>
										Clear All
									</button>
								</div>
								<AmenityFilter
									amenities={
										data.data?.locations.find(
											(location) => location.id === 1
										)?.amenities ?? []
									}
								/>
							</>
						)}
					</div>
					{/* <div className="w-full pt-6">
						<div className="flex items-center justify-between pb-2">
							<h1 className="text-sm">Select Amenities</h1>
							<button className="text-[10px] text-basecolor">
								Clear All
							</button>
						</div>
						<AmenityFilter
							handleAddSelectedAmenity={handleAddSelectedAmenity}
							handleRemoveSelectedAmenity={
								handleRemoveSelectedAmenity
							}
							amenities={amenities}
							selectedAmenities={selectedAmenities}
						/>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default RoomFilter;
