import CustomCheckbox from "@/components/input/CustomCheckbox";
import FormInput from "@/components/input/FormInput";
import { LoaderButton } from "@/components/Loader/LoaderButton";
import { useAppSelector } from "@/hooks/hooks";
import {
	useAddNewAmenityMutation,
	useGetAllAdminLocationRoomsQuery,
} from "@/redux/features/apiSlice";
import { notifyWithIcon } from "@/services/utilities";
import { TeamMemberRoles } from "@/types/team-member";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

// interface AmenityData {
//     name: string;
//     rooms: { id: string, name: string }[];
// }

interface AddAmenitiesProps {
	setShow: Dispatch<SetStateAction<boolean>>;
}

const AddAmenities = ({ setShow }: AddAmenitiesProps) => {
	const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	const id = location.pathname.split("/").pop();
	const [, setSelectAll] = useState(false);
	const { data, isSuccess, isError } = useGetAllAdminLocationRoomsQuery({
		id: parseInt(id!),
	});
	const [
		addNewAmenity,
		{
			isError: isAmenityAddedError,
			isSuccess: isAmenityAddedSuccess,
			isLoading: isAmenityAddLoading,
		},
	] = useAddNewAmenityMutation();
	const [allRooms, setAllRooms] = useState([]);
	const [selectedRooms, setSelectedRooms] = useState<
		{ id: string; name: string }[]
	>([]);
	const [amenityName, setAmenityName] = useState("");

	useEffect(() => {
		if (isSuccess) {
			setAllRooms(
				data.data.map((room) => ({ name: room.name, id: room.id }))
			);
			// console.log(data.data)
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isAmenityAddedSuccess) {
			notifyWithIcon("", "Amenity added successfully.");
			setShow(false);
		} else if (isAmenityAddedError) {
			notifyWithIcon("", "An error occurred while adding amenity.");
		}
	}, [isAmenityAddedError, isAmenityAddedSuccess]);

	const handleCheckboxChange = (
		room: { id: string; name: string },
		isChecked: boolean
	) => {
		setSelectedRooms((prev) => {
			if (isChecked) {
				const updatedRooms = [...prev, room];
				if (updatedRooms.length === allRooms.length) {
					setSelectAll(true); // Check select all if all individual checkboxes are checked
				}
				return updatedRooms;
			} else {
				setSelectAll(false); // Uncheck select all if any individual checkbox is unchecked
				return prev.filter((r) => r.id !== room.id);
			}
		});
	};

	// const handleSelectAllChange = (isChecked: boolean) => {
	// 	setSelectAll(isChecked);
	// 	if (isChecked) {
	// 		setSelectedRooms(allRooms);
	// 	} else {
	// 		setSelectedRooms([]);
	// 	}
	// };

	const handleAmenityNameChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setAmenityName(e.target.value);
	};

	const handleSubmit = async () => {
		if (
			userRole === TeamMemberRoles.TeamMember ||
			userRole === TeamMemberRoles.PartnerManager
		)
			return notifyWithIcon(
				"error",
				"You are not allowed to perform this action"
			);
		if (amenityName && selectedRooms.length > 0) {
			const amenityData = {
				name: amenityName,
				rooms: selectedRooms.map((room) => room.id),
			};
			const formData = {
				id: id!,
				data: amenityData,
			};
			await addNewAmenity(formData);
			setSelectedRooms([]);
			setAmenityName("");
		} else {
			notifyWithIcon(
				"",
				"Please enter an amenity name and select at least one room."
			);
		}
	};

	return (
		<div className="w-full">
			<div className="flex items-center justify-between pb-2">
				<p className="text-lg font-medium text-basecolor">
					Add Amenity
				</p>
			</div>

			<div className="flex flex-col gap-2 pb-1">
				<div className="flex flex-col gap-2 pt-2">
					<FormInput
						name="amenity_name"
						inputType="text"
						placeholder="Amenity Name"
						value={amenityName}
						onChange={handleAmenityNameChange}
					/>

					{/* <div className="flex items-center space-x-2">
						<label
							className="relative flex cursor-pointer items-center rounded-full"
							htmlFor="check"
						>
							<input
								type="checkbox"
								className="peer relative h-[17px] w-[17px] cursor-pointer appearance-none rounded-md border border-[#E5E5E7] transition-all checked:border-[#195388] checked:bg-[#195388] hover:before:opacity-10"
								checked={selectAll}
								onChange={() =>
									handleSelectAllChange(!selectAll)
								}
							/>
							<span className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-[11px] w-[11px]"
									viewBox="0 0 20 20"
									fill="currentColor"
									stroke="currentColor"
									strokeWidth="1"
								>
									<path
										fillRule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
						</label>
						<p>Select All</p>
					</div> */}
					<div className="flex flex-col space-y-2">
						<p className="text-gray-300 font-medium">Select rooms to attach amenity</p>
						
						{isSuccess && allRooms.length
							? allRooms.map((room: any, id) => (
									<div 
										key={id}
										className="flex cursor-pointer items-center space-x-2"
										onClick={() =>
											handleCheckboxChange(
												room,
												!selectedRooms.includes(room)
											)
										}
									>
										<CustomCheckbox
											checked={selectedRooms.includes(
												room
											)}
											onChange={(e) =>
												handleCheckboxChange(
													room,
													e.target.checked
												)
											}
											htmlFor="check"
											id={room}
										/>
										<p>{room.name}</p>
									</div>
								))
							: ""}
						{isSuccess && !allRooms.length && <p>No spaces</p>}
						{isError && <p>Error Fetching spaces </p>}
					</div>
				</div>
				<LoaderButton
					className="ml-auto flex w-fit items-center gap-2"
					onClick={handleSubmit}
					loading={isAmenityAddLoading}
					loaderSize={16}
				>
					Add
				</LoaderButton>
			</div>
		</div>
	);
};

export default AddAmenities;
