import LoggedIn from "@/provider/LoggedIn";
import { Route, Routes } from "react-router-dom";
import VerifyEmail from "./components/partner/auth/VerifyEmail";
import { useAuth } from "./hooks/auth";
import AdminLayout from "./layouts/AdminLayout";
import GuestLayout from "./layouts/GuestLayout";
import PartnerLayout from "./layouts/PartnerLayout";
import RequireAuth from "./provider/RequireAuth";
import { changeTheme } from "./services/utilities";
import Analytics from "./views/admin/Analytics";
import Bookings from "./views/admin/Bookings/Home";
import Display from "./views/admin/Display";
import Locations from "./views/admin/LocationManagement/Home";
import Partners from "./views/admin/PartnerManagement/Home";
import Settings from "./views/admin/Settings/Home";
import TeamMembers from "./views/admin/Team/Home";
import Account from "./views/partner/Account";
import Login from "./views/partner/Login";
import MeetingDetails from "./views/partner/MeetingDetails";
import MeetingInvitation from "./views/partner/MeetingInvitation";
import Meetings from "./views/partner/Meetings";
import Members from "./views/partner/Members";
import Register from "./views/partner/Register";
import Rooms from "./views/partner/Rooms";
import { DEFAULT_THEME } from "./services/constants";
import useHeapAnalyticsIdentify from "./hooks/useHeapAnalyticsIdentify";
// import useHelpCrunchIdentify from "./hooks/useHelpCrunchIdentify";
// import useHelpIntercomIdentify from "./hooks/useHelpIntercomIdentify";

const AppRoutes = () => {
	const { user } = useAuth();

	if (user && user.business && user.business.theme)
		changeTheme(user.business.theme ?? DEFAULT_THEME);
	useHeapAnalyticsIdentify();
	return (
		<Routes>
			<Route element={<LoggedIn user={user} />}>
				<Route path="/" element={<GuestLayout />}>
					<Route path="/" index element={<Login />} />
					<Route path="/login" element={<Login />} />
					<Route path="/register" element={<Register />} />
				</Route>
			</Route>
			<Route element={<RequireAuth user={user} />}>
				<Route path="/partner" element={<PartnerLayout />}>
					<Route path="/partner/meetings/" element={<Meetings />} />
					<Route
						path="/partner/meeting/:id"
						element={<MeetingDetails />}
					/>
					<Route path="/partner/rooms" element={<Rooms />} />
					<Route path="/partner/my-business" element={<Members />} />
					<Route path="/partner/account" element={<Account />} />
				</Route>
				<Route path="/admin" element={<AdminLayout />}>
					<Route path="/admin/display" element={<Display />} />
					<Route path="/admin/analytics" element={<Analytics />} />
					<Route
						path="/admin/partner-management/*"
						element={<Partners />}
					/>
					<Route
						path="/admin/location-management/*"
						element={<Locations />}
					/>
					<Route
						path="/admin/team-members/*"
						element={<TeamMembers />}
					/>
					<Route path="/admin/bookings/*" element={<Bookings />} />
					<Route path="/admin/settings/*" element={<Settings />} />
				</Route>
			</Route>

			<Route path="/verify-email" element={<VerifyEmail />} />
			<Route
				path="/meeting-invite/:meeting_id"
				element={<MeetingInvitation />}
			/>
			<Route path="*" element={<h1>404</h1>} />
		</Routes>
	);
};

export default AppRoutes;
