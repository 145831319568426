import AmenityHolder from "@/components/common/AmenityHolder";
import CapacityHolder from "@/components/common/CapacityHolder";
import ImageCarousel from "@/components/common/ImageCarousel";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
// import { useAppSelector } from "@/hooks/hooks";
import { Room } from "@/types/admin/booking";
// import { TeamMemberRoles } from "@/types/team-member";

interface RoomDetailsProps {
	room: Room;
	timeslots: string[];
	handleActiveTab: (tab: any) => void;
}

const RoomDetails = ({
	room,
	// timeslots,
	handleActiveTab,
}: RoomDetailsProps) => {
	// const userRole = useAppSelector((state) => state?.user?.user?.role ?? "");
	// const dispatch = useAppDispatch();

	// const selectedTimeSlot = useAppSelector(
	// 	(state) => state.bookings.booking.startTime
	// );
	// const { date, isAllDay, duration } = useAppSelector(
	// 	(state) => state.bookings.booking
	// );
	// const handleSelectTimeSlot = (timeSlot: string) => {
	// 	dispatch(updateBooking({ startTime: timeSlot }));
	// };

	return (
		<div className="flex max-w-2xl flex-col gap-y-3">
			<div className="w-full">
				{room.photos && room.photos.length ? (
					<ImageCarousel images={room?.photos} />
				) : (
					<div className="flex h-[100px] w-full flex-col justify-center rounded-xl bg-[#DCDCDC] px-4 md:h-[192px]">
						<div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#C7C7C7] ">
							<p className="select-none text-lg font-semibold text-white">
								{room?.name.split(" ")[0][0].toUpperCase()}
							</p>
						</div>
					</div>
				)}
			</div>
			<div>
				<div className="flex items-center justify-between">
					<div className="">
						<span className="flex items-center gap-2 text-[10px]">
							<i className="mgc_information_line before:!text-[#323539]" />
							<span className="text-[14px] font-semibold">
								{room?.name}
							</span>
						</span>
						{room?.location?.address && (
							<span className="flex items-center gap-2 text-[10px]">
								<i className="mgc_location_3_line text-[12px] before:!text-[#323539]" />
								<span>
									{room?.location.address} | {room.address}
								</span>
							</span>
						)}
					</div>
					<CapacityHolder capacity={room?.capacity} />
				</div>
				<p className="pt-2 text-[14px] font-light">
					{room?.description ??
						"No description available for this space "}
				</p>
			</div>
			{/* availability  */}
			{/* <div className="space-y-2">
				<h1 className="text-[14px] font-medium">Availability</h1>
				<div className="flex flex-wrap gap-2">
					<DatePicker
						title="Select Date"
						className="w-fit"
						date={
							date
								? DateTime?.fromISO(date).toJSDate()
								: undefined
						}
						setDate={(date) => {
							const luxondate =
								DateTime.fromJSDate(date).toISODate();
							dispatch(updateBooking({ date: luxondate! }));
						}}
					/>
					<CustomSelect
						className="w-full max-w-[160px] text-[14px]"
						placeholder={
							<div className="flex items-center gap-3">
								<FaRegClock /> Duration
							</div>
						}
						options={durationOptions}
						value={{
							label: duration.toString() + " minutes",
							value: duration.toString(),
						}}
						onChange={(newValue: any) => {
							dispatch(
								updateBooking({
									duration: parseInt(newValue.value),
								})
							);
						}}
					/>
					<CustomSelect
						className="w-full max-w-[185px]  text-[14px]"
						placeholder="Does not repeat"
						options={RepeatTimeOptions}
					/>
					<div className="flex items-center space-x-2">
						<Switch
							id="all-day"
							checked={isAllDay}
							onClick={() =>
								dispatch(updateBooking({ isAllDay: !isAllDay }))
							}
						/>
						<p>All day</p>
					</div>
				</div>
				
				<TimeSlotPicker
					selectedTimeSlot={selectedTimeSlot}
					selectTimeSlot={handleSelectTimeSlot}
					timeslots={timeslots}
					slotAmount={6}
					isLoading={getAdminRoomTimeSlotsIsLoading}
				/>
				
			</div> */}
			{/* Amenities  */}
			<div className="mb-4 space-y-2">
				<h1 className="text-[14px] font-medium">Amenities</h1>
				<div className="flex items-center gap-2">
					{room?.amenities.length ? (
						room?.amenities?.map((amenity: any, index: number) => (
							<AmenityHolder
								key={index}
								amenity={amenity.name}
								variant="list"
							/>
						))
					) : (
						<p className="text-sm font-light">
							No amenities available
						</p>
					)}
				</div>
			</div>

			<div className=" mt-auto px-4">
				<div className="flex w-full gap-2">
					<DialogClose className="w-full rounded-md border border-primary bg-transparent px-4 py-2 text-primary">
						Cancel
					</DialogClose>

					{/* Disable this if other fields are not filled  */}
					{/* //todo: fix permissions  */}
					{/* {userRole !== TeamMemberRoles.PartnerAdmin && ( */}
						<Button
							className="w-full"
							variant="default"
							onClick={() => handleActiveTab("reservation")}
						>
							Reserve
						</Button>
					{/* )} */}
				</div>
			</div>
		</div>
	);
};

export default RoomDetails;
