// userSlice.ts
import { IUser, IUserPartner } from "@/types/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
	user: IUser & IUserPartner | null;
	loading: boolean;
	error: string | null;
	loggedIn: boolean;
}

const initialState: UserState = {
	user: null,
	loggedIn: false,
	loading: false,
	error: null,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		loginStart(state) {
			state.loading = true;
			state.error = null;
		},
		loginSuccess(state, action: PayloadAction<IUser & IUserPartner>) {
			state.user = action.payload;
			state.loggedIn = true;
			state.loading = false;
		},
		loginFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		logOut() {
			localStorage.clear();
			return initialState;
		},
		// Other reducers for updating user details, etc.
	},
});

export const { loginStart, loginSuccess, loginFailure, logOut } =
	userSlice.actions;
export const selectPartnerCompanyId = (state) => state.user.user?.id;
export default userSlice.reducer;
