import { AuthData } from "@/provider/AuthProvider";
import { ADMIN_URI, BOOKING_URI, BUSINESS_URI } from "@/services/constants";
import LocalStorage from "@/services/storage";
import { PartnerCompany } from "@/types/partners/member";
import {
	AdminAddPartnerParams,
	ChangePasswordParams,
} from "@/types/team-member";
import { apiSlice } from "../apiSlice";
import { Booking, MeetingInvitation } from "@/types/admin/booking";

const storage = new LocalStorage();

export const adminEndpoints = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllAdminBookings: builder.query<
			{ data: Booking[] },
			{ status?: number }
		>({
			query: (status) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/bookings`,
					params: status,
					method: "get",
				};
			},
			providesTags: ["Adminbooking"],
		}),
		getAllAdminLocationRooms: builder.query<any, { id: number }>({
			query: ({ id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/rooms`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["AdminLocations"],
		}),
		getAllAdminLocationAmenities: builder.query<any, { id: number }>({
			query: ({ id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/amenities`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["AdminLocations"],
		}),
		getAllAdminLocations: builder.query<any, any>({
			query: () => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["AdminLocations"],
		}),
		getAllAdminPartners: builder.query<
			{ data: PartnerCompany[] },
			{ businessId: number }
		>({
			query: ({ businessId }) => ({
				url: `/${ADMIN_URI}/${businessId}/partner-companies`,
				method: "get",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			providesTags: ["AdminPartners"],
		}),
		getAllAdminPartnerDetails: builder.query<
			{ data: PartnerCompany },
			{ partnerId: number | string }
		>({
			query: ({ partnerId }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/partner-companies/${partnerId}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["AdminPartnerDetail"],
		}),
		// get admin booking details by id
		getAdminBookingDetails: builder.query<any, { booking_id: number }>({
			query: ({ booking_id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/bookings/${booking_id}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["AdminPartnerBookingDetail", "PartnerMeetingRooms"],
		}),
		deleteAdminPartner: builder.mutation<
			any,
			{
				company_ids: number[];
				
			}
		>({
			query: (data) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/partner-companies`,
					method: "delete",
					data: data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["AdminPartners"],
		}),
		//add new partner
		addNewPartner: builder.mutation<
			any,
			{
				data: AdminAddPartnerParams;
			}
		>({
			query: ({ data }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/partner-requests`,
					method: "post",
					data: data,
				};
			},
			invalidatesTags: ["AdminPartners"],
		}),
		updateAdminBusiness: builder.mutation<unknown, FormData>({
			query: (data) => ({
				url: `/${BUSINESS_URI}/update`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
		}),
		updateAdminBusinessLogo: builder.mutation<unknown, FormData>({
			query: (data) => ({
				url: `/${BUSINESS_URI}/update-logo`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
		}),
		updateAdminProfile: builder.mutation<
			unknown,
			{ name: string; email?: string; phone_number?: string }
		>({
			query: (data) => ({
				url: `/change-profile`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
		}),
		updateTheme: builder.mutation<
			unknown,
			{ id: number | string; data: { theme: string } }
		>({
			query: ({ id, data }) => ({
				url: `/change-theme/${id}`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
		}),
		getInformation: builder.mutation<AuthData, Object>({
			query: () => ({
				url: `/user-information`,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
		}),
		changePassword: builder.mutation<unknown, ChangePasswordParams>({
			query: (data) => ({
				url: `/change-password`,
				method: "post",
				data,
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
		}),
		getGuestDetail: builder.query<
			{ data: MeetingInvitation },
			{ meeting_id: string }
		>({
			query: ({ meeting_id }) => ({
				url: `/${BOOKING_URI}/guest-booking-detail/${meeting_id}`,
				method: "get",
				headers: {
					Authorization: "Bearer " + storage.getItem("TOKEN_COOKIE"),
				},
			}),
			providesTags: ["PartnerMeetingRooms"],
		}),
		// get admin booking details by id
		// adminBookingAction: builder.query<
		// 	any,
		// 	{ booking_id: number }
		// >({
		// 	query: ({ booking_id }) => ({
		// 		url: `/${ADMIN_URI}/${business_id}/bookings/${booking_id}`,
		// 		method: "get",
		// 		headers: {
		// 			Authorization:
		// 				"Bearer " +
		// 				storage.getItem("TOKEN_COOKIE"),
		// 		},
		// 	}),
		// 	providesTags: ["AdminPartnerBookingDetail"],
		// }),
	}),
});

export const {
	useGetAllAdminBookingsQuery,
	useGetAllAdminLocationRoomsQuery,
	useGetAllAdminLocationAmenitiesQuery,
	useGetAllAdminLocationsQuery,
	useGetAllAdminPartnersQuery,
	useGetAllAdminPartnerDetailsQuery,
	useGetGuestDetailQuery,
	useGetAdminBookingDetailsQuery,
	useDeleteAdminPartnerMutation,
	useAddNewPartnerMutation,
	useUpdateAdminBusinessMutation,
	useUpdateAdminBusinessLogoMutation,
	useUpdateAdminProfileMutation,
	useUpdateThemeMutation,
	useGetInformationMutation,
	useChangePasswordMutation,
} = adminEndpoints;
