import useIntercomIdentify from "@/hooks/useIntercomIdentify";
import useHeapAnalyticsIdentify from "@/hooks/useHeapAnalyticsIdentify";
import { IUser, IUserPartner } from "@/types/user";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth: React.FC<{ user: IUser | IUserPartner | null }> = ({
	user,
}) => {
	const location = useLocation();
	useIntercomIdentify();
	useHeapAnalyticsIdentify();

	if (!user) {
		const redirect = "/login?redirect=" + location.pathname;
		return <Navigate to={redirect} state={{ from: location }} replace />;
	}

	return <Outlet />;
};

export default RequireAuth;
