import { axiosBaseQuery } from "@/services/axios";
import { ADMIN_URI, BOOKING_URI } from "@/services/constants";
import LocalStorage from "@/services/storage";
import {
	Booking,
	BookingStatus,
	BookMeeting,
	Room,
} from "@/types/admin/booking";
import { BookingLocation } from "@/types/location";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Location } from "@/types/location.ts";

const storage = new LocalStorage();

//   };
export const apiSlice = createApi({
	reducerPath: "api",
	baseQuery: axiosBaseQuery(),
	tagTypes: [
		"Analytics",
		"AdminLocations",
		"AdminMeetingRooms",
		"AdminPartnerRooms",
		"AdminPartnerUsers",
		"PartnerRooms",
		"RoomTimeslots",
		"Adminbooking",
		"Booking",
		"PartnerMeetingRooms",
		"Amenities",
		"AdminPartnerDetail",
		"AdminPartners",
		"PartnerUsers",
		"PartnerDetails",
		"AdminPartnerBookingDetail",
		"Amenities",
		"TeamMembers",
		"Location",
		"Guests",
		"UserInfo",
	],
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (data) => ({
				url: "/login",
				method: "post",
				data,
			}),
		}),
		logout: builder.mutation({
			query: () => ({
				url: "/logout",
				method: "post",
			}),
		}),
		//update any to post object

		getBookingDetails: builder.query({
			query: () => ({ url: "" }),
		}),
		addNewBooking: builder.mutation({
			query: () => ({
				url: "/mutation",
				method: "post",
			}),
		}),
		getTimeSlots: builder.query({
			query: (id) => ({
				url: `/${id}`,
				method: "get",
			}),
		}),
		getRooms: builder.query<{ data: Room[] }, any>({
			query: (filters) => {
				const queryParams = new URLSearchParams(filters);
				const partner_company_id =
					localStorage.getItem("MG:COMPANYID") || null;
					console.log(partner_company_id)
				return {
					url: `/${BOOKING_URI}/${partner_company_id}/booking/rooms?${queryParams}`,
					method: "get",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			providesTags: ["PartnerRooms"],
		}),
		editRoom: builder.mutation<
			any,
			{ id: string; room_id: string; data: any }
		>({
			query: ({ id, room_id, data }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/rooms/${room_id}`,
					method: "post",
					data,
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
						"content-type": "multipart/form-data",
					},
				};
			},
			invalidatesTags: ["AdminPartnerRooms"],
		}),
		deleteRoom: builder.mutation<any, { id: string; room_id: string }>({
			query: ({ id, room_id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/rooms/${room_id}`,
					method: "delete",
					headers: {
						Authorization:
							"Bearer " + storage.getItem("TOKEN_COOKIE"),
					},
				};
			},
			invalidatesTags: ["AdminPartnerRooms"],
		}),
		addRoom: builder.mutation<any, { id: string; data: any }>({
			query: ({ id, data }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/rooms`,
					method: "post",
					data,
				};
			},
			invalidatesTags: ["AdminPartnerRooms"],
		}),
		addNewAmenity: builder.mutation<any, { id: string; data: any }>({
			query: ({ id, data }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/amenities`,
					method: "post",
					data,
				};
			},
			invalidatesTags: ["Amenities", "AdminPartnerRooms"],
		}),

		editAmenity: builder.mutation<
			any,
			{ id: string; amenity_id: string; data: any }
		>({
			query: ({ id, amenity_id, data }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/amenities/${amenity_id}`,
					method: "post",
					data,
				};
			},
			invalidatesTags: ["Amenities", "AdminPartnerRooms"],
		}),
		deleteAmenity: builder.mutation<
			any,
			{ id: string; amenity_id: string }
		>({
			query: ({ id, amenity_id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/amenities/${amenity_id}`,
					method: "delete",
				};
			},
			invalidatesTags: ["Amenities", "AdminPartnerRooms"],
		}),
		getAllAdminLocationDetail: builder.query<any, { id: number }>({
			query: ({ id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}`,
					method: "get",
				};
			},
		}),
		getAllAdminLocationRooms: builder.query<any, { id: number }>({
			query: ({ id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/rooms`,
					method: "get",
				};
			},
			providesTags: (_, __, arg) => [
				{ type: "AdminPartnerRooms", id: arg.id },
			],
		}),
		getAllAdminLocationAmenities: builder.query<any, { id: number }>({
			query: ({ id }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations/${id}/amenities`,
					method: "get",
				};
			},
			providesTags: ["Amenities"],
		}),
		getAllAdminLocations: builder.query<{ data: BookingLocation[] }, void>({
			query: () => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/locations`,
					method: "get",
				};
			},
			providesTags: ["Location"],
		}),
		addLocation: builder.mutation<
			{
				status: string;
				message: string;
				data: BookingLocation;
			},
			{ id: number; data: Location }
		>({
			query: ({ id, data }) => ({
				url: `/${ADMIN_URI}/${id}/locations`,
				method: "post",
				data,
			}),
			invalidatesTags: ["Location"],
		}),
		// Update admin booking status
		updateAdminBookingStatus: builder.mutation<
			any,
			{
				booking_id: string;
				data: {
					status: BookingStatus;
					message_to_host?: string;
					reschedule_to?: string;
					room_id?: string;
				};
			}
		>({
			query: ({ booking_id, data }) => {
				const business_id = localStorage.getItem("ID") || null;
				return {
					url: `/${ADMIN_URI}/${business_id}/bookings/${booking_id}`,
					method: "post",
					data: data,
				};
			},
			invalidatesTags: ["Adminbooking", "AdminPartnerBookingDetail"],
		}),
		bookRoom: builder.mutation<
			{ data: Booking[] },
			{ business_or_company_id: number; data: BookMeeting }
		>({
			query: ({ business_or_company_id, data }) => {
				// const business_or_company_id = localStorage.getItem("ID") || null;
				return {
					url: `/room_booking/booking/${business_or_company_id}/book`,
					method: "post",
					data: data,
				};
			},
			// invalidatesTags: ["PartnerMeetingRooms", "AdminMeetingRooms"],
		}),
	}),
});

export const {
	useGetRoomsQuery,
	useBookRoomMutation,
	useGetAllAdminLocationsQuery,
	useGetTimeSlotsQuery,
	useGetAllAdminLocationDetailQuery,
	useGetAllAdminLocationRoomsQuery,
	useGetAllAdminLocationAmenitiesQuery,
	useAddNewBookingMutation,
	useAddNewAmenityMutation,
	useEditAmenityMutation,
	useDeleteAmenityMutation,
	useLoginMutation,
	useAddRoomMutation,
	useEditRoomMutation,
	useDeleteRoomMutation,
	useLogoutMutation,
	useUpdateAdminBookingStatusMutation,
	useAddLocationMutation,
} = apiSlice;
